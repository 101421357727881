// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-engines-tsx": () => import("./../../../src/pages/engines.tsx" /* webpackChunkName: "component---src-pages-engines-tsx" */),
  "component---src-pages-genuine-parts-tsx": () => import("./../../../src/pages/genuine-parts.tsx" /* webpackChunkName: "component---src-pages-genuine-parts-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-testing-tsx": () => import("./../../../src/pages/testing.tsx" /* webpackChunkName: "component---src-pages-testing-tsx" */),
  "component---src-templates-engine-detail-tsx": () => import("./../../../src/templates/engine-detail.tsx" /* webpackChunkName: "component---src-templates-engine-detail-tsx" */)
}


export default {
  colors: {
    red: "#E60012",
    black: "black",
    white: "white"
  },
  fonts: {
    sans: '"Trade Gothic LT W05 Roman", sans-serif',
    sansHeavy: '"Trade Gothic LT W05 Bd Cn No-2", sans-serif'
  },
  breakpoints: ["520px", "769px", "992px", "1200px", "1440px"],
  fontWeights: {},
  fontSizes: {
    ultra: 280,
    "xxx-large": 145,
    "xx-large": 118,
    "x-large": 62,
    large: 42,
    medium: 25,
    small: 18,
    "x-small": 16,
    "xx-small": 14,
    "xxx-small": 12
  }
};
